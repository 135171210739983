import React from "react";
import "./About.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding, faHouse, faUser } from "@fortawesome/free-solid-svg-icons";

const About = () => {
  return (
    <div className="aboutcontainer">
      <div className="about">
        <div className="icon">
          <FontAwesomeIcon size="lg" icon={faUser} />
        </div>
        <div className="title">About Us</div>
        <div className="subtitle">
          Developer Summarecon Crown Gading adalah Summarecon Agung Tbk,
          perusahaan pengembang properti terkemuka di Indonesia. Summarecon
          Agung Tbk didirikan pada tahun 1975 dan telah mengembangkan berbagai
          proyek properti yang sukses di seluruh Indonesia.
        </div>
      </div>
      <div className="about">
        <div className="icon">
          <FontAwesomeIcon size="lg" icon={faHouse} />
        </div>
        <div className="title">Residential</div>
        <div className="subtitle">
          Summarecon memiliki reputasi sebagai salah satu perusahaan properti
          terkemuka di Indonesia. Khususnya dalam pengembangan kota mandiri.
          Summarecon mengembangkan kota mandiri yang mengintegrasikan
          pengembangan perumahan dengan pengembangan komersial yang didukung
          dengan fasilitas yang luas dan lengkap bagi penghuni kota mandiri.
        </div>
      </div>
      <div className="about">
        <div className="icon">
          <FontAwesomeIcon size="lg" icon={faBuilding} />
        </div>
        <div className="title">Commercial</div>
        <div className="subtitle">
          Summarecon Crown Gading adalah sebuah kawasan perumahan dan komersial.
          Pada 2023, Summarecon Crown Gading merilis penjualan perdana ruko
          Gading Bulevar Commercial. Ruko pertama Summarecon Crown Gading berada
          di depan jalan boulevard utama Summarecon Crown Gading Bekasi selebar
          40 meter, kawasan Gading Bulevar terhubung dengan area Food Village.
        </div>
      </div>
    </div>
  );
};

export default About;
