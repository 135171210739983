import React from "react";
import Cardabout from "../../component/Cardabout/Cardabout";

import logo from "../../Media/Logo/jasmialogo.png";
import Card from "../../component/Card/Card";
import data from "./data.json";
import "../Viola/Viola.scss";

import jasmiaabout from "../../Media/Cardabout/Jasmia";
import jasmia9 from "../../Media/Product/Jasmia 9";
import jasmia10 from "../../Media/Product/Jasmia 10";
import jasmia12 from "../../Media/Product/Jasmia 12";
import jasmiaattic from "../../Media/Product/Jasmia Attic";

const Jasmia = () => {
  const imageMap = {
    jasmia9,
    jasmia10,
    jasmia12,
    jasmiaattic,
  };

  return (
    <div id="rumahmewah">
      <Cardabout
        logo={logo}
        carousel={jasmiaabout}
        des1={
          "Jasmia Residence dirancang dengan desain klasik modern yang dapat memberikan keindahan dan kemewahan yang abadi dari masa ke masa. Memadukan keseimbangan antara kesegaran alam serta gaya hidup yang modern dan penuh warna, cluster"
        }
        des2={
          "ini merupakan hunian sempurna bagi keluarga untuk tumbuh dan berkembang bersama. Dengan keamanan 24 jam dan fasilitas berkualitas, Jasmia Residence mempersembahkan Anda hidup yang nyaman dan bermakna."
        }
      />
      <div className="cardsection">
        {data.cards.map((card, index) => {
          return (
            <Card
              key={index}
              carousel={imageMap[card.imagesKey]}
              title={card.title}
              subtitle={card.subtitle}
              details={card.details}
              price={card.price}
              whatsappLink={card.whatsappLink}
              styletitle={card.styletitle}
              styleprice={card.styleprice}
              iconcolor={card.iconcolor}
              stylebutton={card.stylebutton}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Jasmia;
