import React from "react";
import "./Surrounding.scss";
import surroundingimg from "../../Media/Surrounding";
import Slider from "react-slick";
import map from "../../Media/Map.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBook,
  faHouse,
  faList,
  faPercent,
} from "@fortawesome/free-solid-svg-icons";

const Surrounding = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
  };
  return (
    <div className="surroundingcont" id="kawasanekslusif">
      <div className="surroundingtitle">SURROUNDING AREA</div>
      <div className="surroundingslider">
        <div className="slidercenter">
          <Slider {...settings}>
            {surroundingimg.map((item, index) => (
              <img
                className="cluster-image"
                key={index}
                src={item}
                alt={`galery ${index + 1}`}
              />
            ))}
          </Slider>
        </div>
      </div>
      <hr />
      <div className="mapcenter">
        <img className="map" alt="scgmap" src={map} />
      </div>
      <hr />
      <div className="information">
        <div className="information-title">More Information</div>
        <div className="information-card">
          <div className="small-card">
            <FontAwesomeIcon
              size="xl"
              icon={faPercent}
              color="#e0ae83"
              style={{ marginTop: "20px" }}
            />
            <div className="cardtitle">Promo</div>
            <div className="cardbutton">
              <button
                onClick={() =>
                  window.open(
                    "https://api.whatsapp.com/send/?phone=6285931970966&text=Halo%20Billy,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20produk%20https://marketingcrowngading.id/&type=phone_number&app_absent=0",
                    "_blank"
                  )
                }
                className="wabutton"
              >
                Whatsapp
              </button>
            </div>
          </div>
          <div className="small-card">
            <FontAwesomeIcon
              size="xl"
              icon={faBook}
              color="#e0ae83"
              style={{ marginTop: "20px" }}
            />
            <div className="cardtitle">Brosur</div>
            <div className="cardbutton">
              <button
                onClick={() =>
                  window.open(
                    "https://api.whatsapp.com/send/?phone=6285931970966&text=Halo%20Billy,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20produk%20https://marketingcrowngading.id/&type=phone_number&app_absent=0",
                    "_blank"
                  )
                }
                className="wabutton"
              >
                Whatsapp
              </button>
            </div>
          </div>
          <div className="small-card">
            <FontAwesomeIcon
              size="xl"
              icon={faList}
              color="#e0ae83"
              style={{ marginTop: "20px" }}
            />
            <div className="cardtitle">Price List</div>
            <div className="cardbutton">
              <button
                onClick={() =>
                  window.open(
                    "https://api.whatsapp.com/send/?phone=6285931970966&text=Halo%20Billy,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20produk%20https://marketingcrowngading.id/&type=phone_number&app_absent=0",
                    "_blank"
                  )
                }
                className="wabutton"
              >
                Whatsapp
              </button>
            </div>
          </div>
          <div className="small-card">
            <FontAwesomeIcon
              size="xl"
              icon={faHouse}
              color="#e0ae83"
              style={{ marginTop: "20px" }}
            />
            <div className="cardtitle">Price List</div>
            <div className="cardbutton">
              <button
                onClick={() =>
                  window.open(
                    "https://api.whatsapp.com/send/?phone=6285931970966&text=Halo%20Billy,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20produk%20https://marketingcrowngading.id/&type=phone_number&app_absent=0",
                    "_blank"
                  )
                }
                className="wabutton"
              >
                Whatsapp
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Surrounding;
