import React from "react";
import "./Hero.scss";
import logo from "../../Media/SCG-P.png";
import Slider from "react-slick";
import hero from "../../Media/Hero";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const Hero = () => {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  return (
    <div className="herocontainer">
      <div className="heroabout">
        <div className="herologo">
          <img alt="scglogo" className="logo" src={logo} />
        </div>
        <div className="herotitle">Summarecon Crown Gading</div>
        <div className="herosubtitle">New City New Dynamic Living</div>
      </div>
      <div className="heroslider">
        <Slider {...settings}>
          {hero.map((item, index) => (
            <img
              className="cluster-image"
              key={index}
              src={item}
              alt={`galery ${index + 1}`}
            />
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Hero;
