import React from "react";
import Cardabout from "../../component/Cardabout/Cardabout";
import violaabout from "../../Media/Cardabout/Viola";
import logo from "../../Media/Logo/violalogo.png";
import Card from "../../component/Card/Card";
import viola5 from "../../Media/Product/Viola 5";
import viola6 from "../../Media/Product/Viola 6";
import viola7 from "../../Media/Product/Viola 7";
import viola8 from "../../Media/Product/Viola 8";
import data from "./data.json";
import "./Viola.scss";

const Viola = () => {
  const imageMap = {
    viola5,
    viola6,
    viola7,
    viola8,
  };

  return (
    <div id="rumahmillenial">
      <Cardabout
        logo={logo}
        headertitle={"New Attic Room"}
        carousel={violaabout}
        des1={
          "Viola Summarecon Crown Gading adalah salah satu proyek perumahan terbaru yang diluncurkan oleh PT Summarecon Agung Tbk di Summarecon Crown Gading, Jakarta Utara.Viola Residence merupakan salah satu tipe rumah yang ditawarkan di proyek ini, dengan opsi ATTIC ROOM. Kawasan ini mengintegrasikan pengembangan perumahan dengan pengembangan komersial yang didukung dengan berbagai fasilitas untuk penduduk kawasan."
        }
        des2={
          "Nama Viola diambil dari bunga violet yang memiliki makna sebuah cinta, kasih sayang dan sebuah kesetiaan. Bunga Violet juga merepresentasikan jalan masa depan yang lebih cerah. Seperti halnya Viola Residence, cluster rumnah terbaru persembahan Summarecon Crown Gading, yang menghadirkan konsep hunian yang nyaman dengan arsitektur modern yang elegan serta dilengkapi dengan attic."
        }
      />
      <div className="cardsection">
        {data.cards.map((card, index) => {
          return (
            <Card
              key={index}
              carousel={imageMap[card.imagesKey]}
              title={card.title}
              subtitle={card.subtitle}
              details={card.details}
              price={card.price}
              whatsappLink={card.whatsappLink}
              styletitle={card.styletitle}
              styleprice={card.styleprice}
              iconcolor={card.iconcolor}
              stylebutton={card.stylebutton}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Viola;
