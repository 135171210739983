import React from "react";
import newlaunchingimage from "../../Media/newlaunching.jpeg";
import "./Newlaunching.scss";

const Newlaunching = () => {
  return (
    <div className="newlaunchingcontainer" id="newlaunch">
      <div className="title">New Launching</div>
      <div className="subtitle">Viola Residence at Summarecon Crown Gading</div>
      <div className="launchingimg">
        <img className="img" alt="newlaunchingscg" src={newlaunchingimage} />
      </div>
    </div>
  );
};

export default Newlaunching;
