import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home/Home";
import { FloatingWhatsApp } from "react-floating-whatsapp";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>
      <a
        rel="noreferrer"
        target="_blank"
        href="https://api.whatsapp.com/send/?phone=6282122005507&text=Halo%20Dean,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20produk%20Navapark%20https://marketingbsdcity-navapark.com/&type=phone_number&app_absent=0"
      >
        <FloatingWhatsApp />
      </a>
    </Router>
  );
}

export default App;
