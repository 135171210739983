import React from "react";
import Slider from "react-slick";

import "./Cardabout.scss";

const Cardabout = ({ logo, headertitle, carousel, des1, des2 }) => {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className="cardaboutcon">
      <div className="cardheader">
        <img alt="scglogo" className="imglogo" src={logo} />
        <div className="headertitle">{headertitle}</div>
      </div>
      <div className="cardcontentcenter">
        <div className="cardcontent">
          <div className="cardcarousel">
            <Slider {...settings}>
              {carousel.map((item, index) => (
                <img
                  className="cluster-image"
                  key={index}
                  src={item}
                  alt={`galery ${index + 1}`}
                />
              ))}
            </Slider>
          </div>
          <div className="carddes">
            {des1}
            <br />
            <br />
            {des2}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cardabout;
